// 网易云音乐api

import { request, requestMusic } from "../Utils/request";
import { API } from "../Services/api";
import { message } from "antd";

export default class Music {
  initStore = {
    randomMusicUrl: "",
    randomPicUrl: "",
    randomArtistsname: "随机热榜歌曲",
    randomMusicName: "点击🔥按钮开始听歌",
    loading: false,
    status: false, // 当前播放状态
    historyMusic: [], // 历史歌曲
    lyrics: [], //歌词信息
    musicUrl: "", // 歌曲url
  };
  reducers = {
    setData: (params) => ({ ...params }),
  };
  asyncEffects = {
    // 获取随机歌曲
    getMusic: async (
      params = { sort: "热歌榜", mid: "", format: "json" },
      store
    ) => {
      const res = await requestMusic.get(API.WYY_randMusic, {
        params,
      });
      if (res.code === 1) {
        const { historyMusic } = store;
        const newMusic = {
          randomMusicUrl: res.data.url,
          randomPicUrl: res.data.picurl,
          randomArtistsname: res.data.artistsname,
          randomMusicName: res.data.name,
        };
        return this.reducers.setData({
          ...newMusic,
          loading: false,
          status: true,
          historyMusic: [...historyMusic, newMusic],
        });
      } else {
        message.error("获取听歌信息失败");
        return this.reducers.setData({
          loading: false,
          status: false,
        });
      }
    },
    // 获取歌词
    getLyrics: async (id) => {
      const res = await requestMusic.get(API.WYY_lyric, {
        params: {
          id,
        },
      });
      if (res.code === 200) {
        return this.reducers.setData({
          lyrics: res.lrc.lyric.split("\n"),
        });
      } else {
        return this.reducers.setData({
          lyrics: "暂未获取到歌词信息",
        });
      }
    },
    // 根据id获取播放地址
    getMusicUrl: async (id) => {
      const res = await requestMusic.get(API.WYY_musicUrl, {
        params: {
          id,
        },
      });
      if (res.code === 200) {
        return this.reducers.setData({
          musicUrl: res.data[0].url,
        });
      } else {
        return this.reducers.setData({
          musicUrl: "",
        });
      }
    },
  };
}
