// 这里是react的路由配置
import { lazy } from "react";
import { Navigate } from "react-router";
import {
  HomeOutlined,
  PlaySquareOutlined,
  AlertOutlined,
  RedditOutlined,
} from "@ant-design/icons";
// 懒加载组件
const LazyLoad = (name, type) => {
  let Component = lazy(() => import(`../src/Pages/${name}`));
  return <Component />;
};
export const RouterConfig = [
  {
    path: "/",
    element: <Navigate to="/home" />,
  },
  {
    menu: true,
    title: "首页",
    path: "/home",
    icon: <HomeOutlined />,
    element: LazyLoad("HomePage"),
  },
  // 音乐API接口停止供应了，取消音乐界面
  // {
  //   path: "/music",
  //   element: LazyLoad("Music"),
  // },
  {
    menu: true,
    title: "视频",
    path: "/video",
    icon: <PlaySquareOutlined />,
    element: LazyLoad("Video"),
  },
  {
    menu: true,
    title: "布灵布灵",
    path: "/bling",
    element: LazyLoad("Bling"),
    icon: <AlertOutlined />,
  },
  {
    menu: true,
    title: "业哥好AI",
    path: "/yai",
    element: LazyLoad("Yai"),
    icon: <RedditOutlined />,
  },
  // {
  //   path: "/test",
  //   element: LazyLoad("Test"),
  // },
  {
    path: "*",
    element: LazyLoad("NotFound"),
  },
];
export const MenuConfig = RouterConfig.filter((item) => item.menu).map(
  (item) => ({
    icon: item.icon,
    label: item.title,
    key: item.path.replace("/", ""),
  })
);
