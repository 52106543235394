// 视频处理的状态存储
import { message } from "antd";

export default class Video {
  initStore = {
    videoFileList: [], // 视频文件列表
    imgFileList: [], // 图片文件列表
    playList: [], // 播放队列
    imgList: [], //图片队列
    targetImgData: {}, //当前编辑的图片信息
  };
  reducers = {
    setData: (params) => ({ ...params }),
  };
  asyncEffects = {};
}
