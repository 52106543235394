// 所有的接口目录
export const API = {
  WYY_randMusic: `/api/rand.music`, //网易云随机音乐api
  WYY_musicUrl: `/song/url`, // 根据id获取播放地址:https://autumnfish.cn
  WYY_musicCategory: `/playlist/highquality/tags`, //歌单分类tags
  WYY_tagsMusic: `/top/playlist/highquality`, //相关歌单推荐
  WYY_playList: `/playlist/detail`, //获取对应歌单的歌曲
  WYY_search: `/search`, //搜索歌曲
  WYY_songId: `/song/url`, //根据音乐id获取音乐详情
  WYY_playListDynamic: `/playlist/detail/dynamic`, //获取歌单详情
  WYY_lyric: `/lyric`, //获取歌词  https://autumnfish.cn
  talk: `/iqa/query`, //智能问答
  channel: `/news/channel`, //首页栏目
  channelNews: `/news/get`, //栏目对应的内容,
  disease: `/g2/getOnsInfo`, //疫情信息  (腾讯接口)
  // 审核平台接口
  get_check_list: "/list", // 获取平台数据
};
