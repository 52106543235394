import { useRoutes } from "react-router";
import { RouterConfig, MenuConfig } from "./router";
import Layouts from "@/Components/Layouts";
import {
  SoundOutlined,
  HomeOutlined,
  PlaySquareOutlined,
  AlertOutlined,
  RedditOutlined,
} from "@ant-design/icons";
import React from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import Store from "./Store";
import { MyContext } from "@/Utils/contextHooks";
import { DndProvider } from "react-dnd";

function App() {
  const { stores } = Store();
  return (
    <MyContext.Provider value={{ ...stores }}>
      <DndProvider backend={HTML5Backend}>
        <Layouts menus={MenuConfig}>{useRoutes(RouterConfig)}</Layouts>
      </DndProvider>
    </MyContext.Provider>
  );
}

export default App;
